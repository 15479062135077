import Vue from 'vue'

/**
 * 用于设置页面标题的 hook，不用手动设置网站名称
 */
export const useDocTitle = () => {
  const tenant = Vue.ls.get('tenant')
  const rootName = tenant ? tenant.name : '亿炼智能'

  /**
   * 给页面提供设置标题的方法
   * @param name
   */
  const set = (name: string) => {
    if (name) {
      document.title = `${name} | ${rootName}`
    }
  }

  /**
   * 其他页面切换时，重置标题
   */
  const reset = () => {
    document.title = rootName
  }

  return { set, reset }
}
