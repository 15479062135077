import useStomp from '@/hook/useStomp'
import { useTenant } from '@/hook/useTenant'
import { AlarmEvent } from '@/types/epp/alarm'
import { notification } from 'ant-design-vue'

export const useAlarmStomp = () => {
  const stomp = useStomp()
  const { currentTenant } = useTenant()

  if (!document.getElementById('alarmAudio')) {
    const newAudio = document.createElement('audio') as HTMLAudioElement
    newAudio.muted = false
    newAudio.src = '/audio/waring.wav'
    newAudio.id = 'alarmAudio'
    document.body.append(newAudio)
  }

  const subscribe = () => {
    const traceId = currentTenant()?.id
    stomp
      .subscribe<AlarmEvent>({
        url: `/topic/iot/alarm/${traceId}`,
        onReceive: (result) => {
          if (!result) {
            return
          }

          const style = {
            lineHeight: '1.8',
            whiteSpace: 'pre-wrap'
          }

          if (result?.alarmStatus === 'ALARM') {
            const audioEl = document.getElementById('alarmAudio') as HTMLAudioElement
            notification.warning({
              message: '提示',
              description: `设备名称: ${result.deviceName}
变量名称: ${result.varName}
触发条件: ${result.triggerCondition}
异常值: ${result.currentValue}
异常内容：${result.triggerCustomContentAlarm.trim()}
异常时间：${result.alarmTimeYmdhm}`,
              duration: 60,
              style,
              onClose: () => {
                if (audioEl) {
                  audioEl.pause()
                }
              }
            })

            if (audioEl) {
              audioEl.currentTime = 0
              audioEl.play()
                .catch((e) => {
                  notification.warning({
                    message: '报警音频播放失败',
                    description: '请点击屏幕以开启音频播放权限'
                  })
                })
            }
          } else {
            notification.success({
              message: '恢复正常',
              description: `设备名称: ${result.deviceName}
变量名称: ${result.varName}
触发条件:${result.triggerCondition}
恢复值: ${result.currentValue}
恢复正常内容：${result.triggerCustomContentNormal.trim()}
恢复时间：${result.alarmTimeYmdhm}`,
              duration: 0,
              style
            })
          }
        }
      })
      .then()
  }

  return { subscribe }
}
