/**
 * 设备传感器数据
 */
export default [
  // 传感器页面
  {
    path: '/sensor',
    name: 'sensor',
    component: () => import('@/views/sensor/SensorIndex.vue')
  },
  // 设备未开放页面
  {
    path: '/sensor/sensor_error_page',
    name: 'sensor_error_page',
    component: () => import('@/views/sensor/components/SensorErrorPage.vue')
  },
  // 绑定的设备页面
  {
    path: '/sensor/mine',
    name: 'sensor_mine',
    component: () => import('@/views/sensor/SensorMine.vue')
  },
  // 扫码成功页面
  {
    path: '/wx/scan/success',
    name: 'wx_scan_success',
    component: () => import('@/views/sensor/components/WxMpScanSuccess.vue')
  }
]
