export default [
    {
        parentPath: '/device',
        path: '/basic/device/device_view',
        name: 'DeviceBasicView',
        hidden: true,
        component: () => import('@epp/basic/device/DeviceBasicView.vue'),
        meta: { title: '基础设备详情页' }
    }
]
