import qs from 'qs'
import { axios } from '@/utils/request'
import { AxiosRequestConfig } from 'axios'

/**
 * Xuz on 2021-12-31.
 * 屏蔽具体 http 请求实现，对 axios 的请求封装
 */
class RequestWrapper {
  /**
   * GET请求
   * @param url 请求URL
   * @param params 请求参数
   * @param config 请求高级配置
   * @return {AxiosPromise<any>}
   */
  get<T> (
    url: string,
    params?: object | undefined,
    config?: AxiosRequestConfig | undefined
  ): Promise<T> {
    let cfg: AxiosRequestConfig = {
      params: {},
      paramsSerializer: undefined
    }
    if (params !== undefined) {
      cfg.params = params
      cfg.paramsSerializer = (ps) => {
        return qs.stringify(ps, { indices: false })
      }
    }
    if (config !== undefined) {
      cfg = { ...cfg, ...config }
    }
    return axios.get<void, T>(url, cfg)
  }

  /**
   * 数据以 form 方式提交 post 请求
   *
   * @param url 请求URL
   * @param data 请求参数 {account:'zhang', password:'123456'}
   * @param config 请求高级配置
   * @returns {AxiosPromise<any>}
   */
  post<T> (url: string, data?: object | undefined, config?: object | undefined): Promise<T> {
    return axios.post<void, T>(url, qs.stringify(data, { indices: false, allowDots: true }), config)
  }

  /**
   * 数据以 json 方式提交 post 请求
   *
   * @param url 请求URL
   * @param data 请求参数 {account:'zhang', password:'123456'}
   * @param config 请求高级配置
   * @returns {AxiosPromise<any>}
   */
  postJson<T> (url: string, data?: object | undefined, config?: object | undefined): Promise<T> {
    return axios.post<void, T>(url, data, config)
  }
}

const request = new RequestWrapper()

export default request
