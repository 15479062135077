<template>
  <div class="setting-drawer">
    <a-drawer
      width="300"
      placement="right"
      :closable="false"
      :visible="false"
      :drawer-style="{ position: 'absolute' }">
      <div class="setting-drawer-index-handle" @click="onAgain" slot="handle" v-if="false">
        <a-tooltip placement="left">
          <template slot="title">
            <span>可重新查看当前页面的功能指引</span>
          </template>
          <a-icon type="question-circle" />
          <p>指引</p>
        </a-tooltip>
      </div>
    </a-drawer>
  </div>
</template>


<script>

export default {
  components: {
  },
  data () {
    return {
      visible: false
    }
  },
  watch: {

  },
  mounted () {
    this.$guide.loadGuideIntro()
  },
  methods: {
    onAgain () {
      this.$emit('onagain', 'again')
    }
  }
}
</script>
<style lang="less" scoped>

.setting-drawer-index-content {

  .setting-drawer-index-blockChecbox {
    display: flex;

    .setting-drawer-index-item {
      margin-right: 16px;
      position: relative;
      border-radius: 4px;
      cursor: pointer;

      img {
        width: 52px;
      }

      .setting-drawer-index-selectIcon {
        position: absolute;
        bottom: 5px;
        right: 0;
        color: #1890ff;
        font-size: 14px;
        font-weight: 700;
        width: 16px;
        height: 16px;
      }
    }
  }
  .setting-drawer-theme-color-colorBlock {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    float: left;
    cursor: pointer;
    margin-right: 8px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    color: #fff;
    font-weight: 700;

    i {
      font-size: 14px;
    }
  }
}

.setting-drawer-index-handle {
  position: absolute;
  bottom: 182px;
  background: #fff;
  width: 48px;
  height: 48px;
  right: 300px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1001;
  text-align: center;
  font-size: 14px;
  border-radius: 4px 0 0 4px;
  color: #1890ff;
  border: 1px solid #1890ff;
  border-right: none;

  i {
    color: #1890ff;
    font-size: 18px;
    display: block;
    margin-top: 4px;
  }
}
</style>
