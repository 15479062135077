<template>
  <a-layout :class="['layout', device]">
    <!-- SideMenu -->
    <a-drawer
        v-if="isMobile()"
        placement="left"
        :wrapClassName="`drawer-sider ${navTheme}`"
        :closable="false"
        :visible="collapsed"
        @close="drawerClose"
    >
      <side-menu
          mode="inline"
          :menus="menus"
          :theme="navTheme"
          :collapsed="false"
          :collapsible="true"
          @menuSelect="menuSelect"
      ></side-menu>
    </a-drawer>

    <side-menu
        v-else-if="isSideMenu()"
        mode="inline"
        :menus="currentMenuSide"
        :theme="navTheme"
        :collapsed="collapsed"
        :collapsible="true"
        @toggle="toggle"
    ></side-menu>

    <a-layout :class="[layoutMode, `content-width-${contentWidth}`]"
              :style="{ paddingLeft: contentPaddingLeft, minHeight: '100vh' }">
      <!-- layout header -->
      <global-header
          :mode="layoutMode"
          :menus="menus"
          :theme="navTheme"
          :collapsed="collapsed"
          :device="device"
          @toggle="toggle"
          :currentMenuHeader="currentMenuHeader"
          :selectedMenuHeader="selectedMenuHeader"
          @currentMenuHeaderClick="currentMenuHeaderClick"
      />

      <!-- layout content -->
      <a-layout-content
          :class="currentMenuSide.length > 0 && currentMenuSide[0].name !== 'Workplace' ? 'show-sidebar': 'hide-sidebar'"
      >
        <multi-tab v-if="multiTab"></multi-tab>
        <transition name="fade" mode="out-in">
          <route-view/>
        </transition>
      </a-layout-content>

      <!-- layout footer -->
      <a-layout-footer>
        <global-footer/>
      </a-layout-footer>

      <FxSettingDrawer></FxSettingDrawer>
      <!-- Setting Drawer (show in development mode) -->

      <fx-guide-drawer @onagain="e=>{this.$guide.loadGuideIntro('again')}"/>
    </a-layout>
  </a-layout>

</template>

<script>
import { triggerWindowResizeEvent } from '@/utils/util'
import { mapState, mapActions } from 'vuex'
import { mixin, mixinDevice } from '@/utils/mixin'
import config from '@/config/defaultSettings'

import RouteView from './RouteView'
import MultiTab from '@/components/MultiTab'
import SideMenu from '@/components/Menu/SideMenu'
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
import SettingDrawer from '@/components/SettingDrawer'
import FxSettingDrawer from '@/components/FxSettingDrawer'
import FxGuideDrawer from '@/views/guide/FxGuideDrawer'

export default {
  name: 'BasicLayout',
  mixins: [mixin, mixinDevice],
  components: {
    FxGuideDrawer,
    RouteView,
    MultiTab,
    SideMenu,
    GlobalHeader,
    GlobalFooter,
    SettingDrawer,
    FxSettingDrawer
  },
  data () {
    return {
      production: config.production,
      collapsed: false,
      menus: [],
      selectedMenuHeader: [],
      currentMenuHeader: [],
      currentMenuSide: []
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters
    }),
    contentPaddingLeft () {
      if (!this.fixSidebar || this.isMobile()) {
        return '0'
      }
      if (this.sidebarOpened) {
        return '256px'
      }
      return '80px'
    }
  },
  watch: {
    sidebarOpened (val) {
      this.collapsed = !val
    },
    $route: {
      handler: function (val, oldVal) {
        if (this.selectedMenuHeader[0] === val.matched[1].path) {
          return false
        }
        this.initMenu(val)
      },
      // 深度观察监听
      deep: true
    }
  },
  created () {
    this.menus = this.mainMenu.find(item => item.path === '/').children
    // 头部菜单
    this.currentMenuHeader = []
    this.menus.forEach(menu => {
      this.currentMenuHeader.push(menu)
    })
    this.initMenu(this.$route)

    this.collapsed = !this.sidebarOpened
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
  },
  methods: {
    ...mapActions(['setSidebar']),
    toggle () {
      this.collapsed = !this.collapsed
      this.setSidebar(!this.collapsed)
      triggerWindowResizeEvent()
    },
    paddingCalc () {
      let left = ''
      if (this.sidebarOpened) {
        left = this.isDesktop() ? '256px' : '80px'
      } else {
        left = (this.isMobile() && '0') || ((this.fixSidebar && '80px') || '0')
      }
      return left
    },
    menuSelect () {
      if (!this.isDesktop()) {
        this.collapsed = false
      }
    },
    drawerClose () {
      this.collapsed = false
    },
    initMenu ($route) {
      this.selectedMenuHeader = []
      this.selectedMenuHeader.push($route.matched[1].path)

      // // 获取一级模块的路径
      const path = '/' + this.selectedMenuHeader[0].split('/')[1]
      this.currentMenuHeader.forEach(header => {
        if (header.path === path) {
          this.currentMenuSide = header.children ? header.children : []
          return true
        }
      })
    },
    currentMenuHeaderClick (menu) {
      let goPath = menu.path
      if (this.multiTab) {
        this.$refs.multiTab.init()
      }
      if (menu.children) {
        const children = menu.children
        this.currentMenuSide = children.filter(item => item.hidden === false)
      } else {
        this.currentMenuSide = []
      }

      if (this.currentMenuSide && this.currentMenuSide.length > 0) {
        goPath = this.currentMenuSide[0].path
      }
      this.$router.push({
        path: goPath
      })
    }
  }
}
</script>

<style lang="less">
@import url('../components/global.less');
@import url('../components/fx.less');
@import url('../components/tree.less');

.fade-enter-active {
  animation-name: example2;
  animation-duration: 0.5s;
}

@keyframes example2 {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
}

.hide-sidebar {
  .page-header {
    .breadcrumb {
      display: none;
    }
  }
}

</style>
