import request from '@/utils/requestWrapper'
import { Result } from '@/types/basic'
import { updateTheme } from '@/components/tools/setting'
import store from '@/store/'

/**
 * 切换租户
 * @param tenantId: 租户ID
 */
export function switchTenant (tenantId: string) {
  return request.post('/api/1/tenant/switch', { tenantId: tenantId })
}

export interface TenantsResult {
  id: string
  name: string
  code: string
  main: boolean
  current: boolean
}

/**
 * 当前用户的租房列表
 */
export function getUserTenants () {
  return request.get<Result<TenantsResult[]>>('/api/1/tenant/get_user_tenants')
}

/**
 * 更新租户主题
 * @param preferences 主题配色
 */
export function updateTenantTheme (preferences: string) {
  if (!preferences) {
    return
  }
  let color = '#0765ff'
  if (preferences === 'red') {
    color = '#F5222D'
  } else if (preferences === 'blue') {
    color = '#0765ff'
  }
  console.log('color: ' + color)
  // store.dispatch('ToggleColor', color)
  updateTheme(color)
}
