<template>
  <a-breadcrumb class="breadcrumb">
    <a-breadcrumb-item v-for="(item, index) in breadList" :key="item.name">
      <span>{{ item.meta.title }}</span>
    </a-breadcrumb-item>

    <a-breadcrumb-item v-show="hasHistory">
      <a style="cursor:pointer;" @click="$router.go(-1)">&laquo; 返回上页</a>
    </a-breadcrumb-item>

  </a-breadcrumb>
</template>

<script>
export default {
  data () {
    return {
      name: '',
      breadList: [],
      hasHistory: false
    }
  },
  created () {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb () {
      this.breadList = []
      // this.breadList.push({name: 'index', path: '/dashboard/', meta: {title: '首页'}})
      this.name = this.$route.name
      this.$route.matched.forEach(item => {
        // item.name !== 'index' && this.breadList.push(item)
        // 面包屑组件不显示组件
        if (item.name !== 'index') {
          this.breadList.push(item)
        }
      })

      if (window.history.length > 3) {
        this.hasHistory = true
      }
    }
  },
  watch: {
    $route () {
      this.getBreadcrumb()
    }
  }
}
</script>

<style scoped>
</style>
