import { isReactive, isRef, Ref, unref } from 'vue'
import { message } from 'ant-design-vue'
import { nanoid } from 'nanoid'

const setLoading = (loading: any, val: boolean) => {
  if (loading === undefined) {
    return
  }

  if (isRef(loading)) {
    loading.value = val
  } else if (isReactive(loading)) {
    loading.loading = val
  } else {
    loading = val
  }
}

/**
 * 异步更新 loading 操作
 * @param loading 需要更新的 loading
 * @param func 异步方法
 */
export const useAsync = async <T>(loading: any | Ref, func: Promise<T>): Promise<T> => {
  setLoading(loading, true)
  return func.finally(() => {
    setLoading(loading, false)
  })
}

export interface usaAsyncMsProps {
  // 顶部消息
  msg: string | Ref<string>
}

/**
 * 异步更新 msg 操作
 * @param params 参数
 * @return {key, loading, ok, fail, destroy}
 */
export const useAsyncMsg = (params: usaAsyncMsProps) => {
  const msg = unref(params.msg)
  const destroy = () => {
    message.destroy()
  }
  const key = nanoid()
  const loading = () => {
    message.loading({ content: `${msg}中`, duration: 0, key })
  }
  const ok = () => {
    message.success({ content: `${msg}成功`, key })
  }

  const fail = (cMsg?:string) => {
    message.error({ content: cMsg || `${msg}失败`, key })
  }

  return {
    // msg 的唯一 key
    key,
    // 开始加载
    loading,
    // 成功消息
    ok,
    // 失败消息
    fail,
    // 直接销毁
    destroy
  }
}
