<template>
  <div class="user-wrapper">

<!--    <header-online class="action"/>-->
    <header-notice class="action" v-if="false"/>
    <HeaderWebSocketStatus/>
    <a-dropdown>
      <span class="action ant-dropdown-link user-dropdown-menu">
        <a-avatar class="avatar" size="small" :src="avatar()"/>
        <span>{{ nickname() }}</span>
      </span>
      <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
        <a-menu-item key="1">
          <router-link :to="{ name: 'settings' }">
            <a-icon type="setting"/>
            <span>个人中心</span>
          </router-link>
        </a-menu-item>
<!--        <a-menu-item key="4">-->
<!--          <router-link :to="{ name: 'tenantSettings' }">-->
<!--            <a-icon type="setting"/>-->
<!--            <span>租户设置</span>-->
<!--          </router-link>-->
<!--        </a-menu-item>-->
        <a-menu-divider/>
        <a-sub-menu key="2">
          <span slot="title"><a-icon type="swap" /><span>切换账号</span></span>
          <a-menu-item v-for="item in tenants" :key="item.id" @click="onSwitchTenant(item.id)">
            <div v-if="item.current" class="tenant-row" style="color: #2e82ff"><span>{{item.name}}</span><a-icon type="check" /></div>
            <div v-else class="tenant-row"><span>{{item.name}}</span></div>
          </a-menu-item>
        </a-sub-menu>
        <a-menu-divider/>
        <a-menu-item key="3">
          <a href="javascript:;" @click="handleLogout">
            <a-icon type="logout"/>
            <span>退出登录</span>
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import HeaderWebSocketStatus from './HeaderWebSocketStatus'
import HeaderNotice from './HeaderNotice'
import HeaderOnline from './HeaderOnline'
import { mapActions, mapGetters } from 'vuex'
import { getUserTenants, switchTenant } from '@/api/app/Tenant'
import { useTenant } from '@/hook/useTenant'
import { useAlarmStomp } from '@/hook/epp/useAlarm'

export default {
  name: 'UserMenu',
  components: {
    HeaderWebSocketStatus,
    HeaderNotice,
    HeaderOnline
  },
  data () {
    return {
      tenants: {}
    }
  },
  created () {
    const { tenants } = useTenant()
    this.tenants = tenants
    useAlarmStomp().subscribe()
  },
  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['nickname', 'avatar']),
    handleLogout () {
      const that = this

      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk () {
          return that.Logout({}).then(() => {
            window.location.reload()
          }).catch(err => {
            that.$message.error({
              title: '错误',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    },
    onSwitchTenant (tenantId) {
      switchTenant(tenantId).then(resp => {
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.tenant-row {
  padding: 6px;
  display: flex;
  overflow: hidden;
  align-items: center;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 168px;
  }
  i {
    flex: 0 0 auto;
    margin-left: 32px;
  }
}

.header-online {
  display: inline-block;
  transition: all 0.3s;

  span {
    vertical-align: initial;
  }
}
</style>
