<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="isRouterAlive"/>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { deviceEnquire, DEVICE_TYPE } from '@/utils/device'
import { version } from 'ant-design-vue'
import { mapState } from 'vuex'
import useStomp from '@/hook/useStomp'

export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      locale: zhCN,
      version,
      isRouterAlive: true
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      globalTheme: state => state.app.globalTheme
    })
  },
  watch: {
    globalTheme: function (newVal, oldVal) {
      let body = document.querySelector('body')
      body.classList.remove(oldVal)
      body.classList.add(newVal)
    }
  },
  mounted () {
    if (this.globalTheme) {
      document.querySelector('body').classList.add(this.globalTheme)
    }

    const { $store } = this
    deviceEnquire(deviceType => {
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:
          $store.commit('TOGGLE_DEVICE', 'desktop')
          $store.dispatch('setSidebar', true)
          break
        case DEVICE_TYPE.TABLET:
          $store.commit('TOGGLE_DEVICE', 'tablet')
          $store.dispatch('setSidebar', false)
          break
        case DEVICE_TYPE.MOBILE:
        default:
          $store.commit('TOGGLE_DEVICE', 'mobile')
          $store.dispatch('setSidebar', true)
          break
      }
    })
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style>
  #app {
    height: 100%;
  }
</style>
