<template>
    <span>
        <a-tooltip placement="bottom">
              <template slot="title">
                <span>在线用户</span>
              </template>
          <span @click="onViewOnline" class="header-online">
              <a-icon style="font-size: 16px;" type="team"/>
          </span>
        </a-tooltip>
        <online-users ref="onlineUsers" :visible="modalVisible" @cancel="modalVisible = false"/>
    </span>
</template>

<script>
import OnlineUsers from '@/views/org/OnlineUsers'
import FxWSClient from '../../utils/wsc-ts.ts'
import useStomp from '@/hook/useStomp'

export default {
  name: 'HeaderOnline',
  components: { OnlineUsers },
  data () {
    return {
      modalVisible: false,
      wsClient: null
    }
  },
  mounted () {
    const user = this.$ls.get('user')

    // this.wsClient = FxWSClient.getInstance({ ctx: this.$_c })
    // this.wsClient.connect(() => {
    //   this.wsClient.subscribeTasksChange(user.id, () => {
    //     console.log('待办数量发生变化')
    //   })
    // })
    const ws = useStomp()
    ws.subscribe({
      url: user.id,
      onReceive: () => {
        console.log('待办数量发生变化')
      }
    })
  },
  beforeDestroy: function () {
    console.log('beforeDestroy...')
    this.wsClient.disconnect()
  },
  methods: {
    onViewOnline () {
      this.modalVisible = true
      this.$refs.onlineUsers.preOnlineUsers()
    }
  }
}
</script>

<style lang="less" scoped>
.header-online {
  display: inline-block;
  transition: all 0.3s;

  span {
    vertical-align: initial;
  }
}
</style>
