<template>
  <!-- , width: fixedHeader ? `calc(100% - ${sidebarOpened ? 256 : 80}px)` : '100%'  -->
  <a-layout-header v-if="!headerBarFixed"
                   :class="[fixedHeader && 'ant-header-fixedHeader', sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed', ]"
                   :style="{ padding: '0' }">
    <div v-if="mode === 'sidemenu'" class="header" :class="className">
      <div class="logo">
        <router-link :to="{name:'dashboard'}">
          <img alt="logo" :src="logoUrl"/>
          <!--              <h1>ML AIoT</h1>-->
        </router-link>
      </div>
<!--                  <a-icon-->
<!--                          v-if="device==='mobile'"-->
<!--                          class="trigger"-->
<!--                          :type="collapsed ? 'menu-fold' : 'menu-unfold'"-->
<!--                          @click="toggle"></a-icon>-->
<!--                  <a-icon-->
<!--                          v-else-->
<!--                          class="trigger"-->
<!--                          :type="collapsed ? 'menu-unfold' : 'menu-fold'"-->
<!--                          @click="toggle"/>-->
      <div class="header_con">
        <a-dropdown :trigger="['click']" style="cursor: pointer;" v-if="tenants.length > 1">
          <div class="enterprise"  @click="e => e.preventDefault()">
            <span class="text">{{currentTenantName}}</span>
            <a-icon class="ml-1" type="down"/>
          </div>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in tenants" :key="item.id" @click="onSwitchTenant(item.id)">
              <div v-if="item.current" class="tenant-row" style="color: #2e82ff"><span>{{item.name}}</span><a-icon type="check" /></div>
              <div v-else class="tenant-row"><span>{{item.name}}</span></div>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div v-else class="enterprise" style="cursor:pointer" @click="()=>{ this.$router.push('/dashboard') }">
            <span class="text">{{currentTenantName}}</span>
        </div>
        <a-menu mode="horizontal" class="header_menu"
                v-model="selectedMenuHeader2"
        >
          <template
              v-for="item in currentMenuHeader"
          >
            <a-menu-item
                :key="item.path"
                @click="$emit('currentMenuHeaderClick', item)"
                v-if="item.hidden === false"
            >
              <a-icon v-if="item.meta.icon && item.meta.icon !== ''" :type="item.meta.icon" />{{ item.meta.title }}
            </a-menu-item>
          </template>
        </a-menu>
        <div class="item">
          <a-dropdown placement="bottomCenter">
            <div>
              <a-icon class="mr-1" style="font-size: 16px;" type="skin"/>
              <span>皮肤</span>
            </div>

            <a-menu slot="overlay">
              <a-menu-item>
                <a class="item_btn" @click="save('')">原始</a>
              </a-menu-item>
              <a-menu-item>
                <a class="item_btn" @click="save('blue')">蓝色</a>
              </a-menu-item>
              <a-menu-item>
                <a class="item_btn" @click="save('black')">黑色</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>

        <div class="item" @click="$router.push('/help')">
          <a-icon class="mr-1" style="font-size: 16px" type="question-circle"/>
          <span>帮助</span>
        </div>

<!--        <div class="item">-->
<!--          <a-icon class="mr-1" style="font-size: 16px" type="bell"/>-->
<!--          <span>通知</span>-->
<!--        </div>-->
      </div>
      <user-menu></user-menu>
    </div>
    <div v-else :class="['top-nav-header-index', theme]">
      <div class="header-index-wide">
        <div class="header-index-left">
          <logo class="top-nav-header" :show-title="device !== 'mobile'"/>
          <s-menu
              v-if="device !== 'mobile'"
              mode="horizontal"
              :menu="menus"
              :theme="theme"
          ></s-menu>
          <a-icon
              v-else
              class="trigger"
              :type="collapsed ? 'menu-fold' : 'menu-unfold'"
              @click="toggle"></a-icon>
        </div>
        <user-menu class="header-index-right"></user-menu>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import UserMenu from '../tools/UserMenu'
import SMenu from '../Menu/'
import Logo from '../tools/Logo'

import { mixin } from '@/utils/mixin'
import { handleScrollHeader } from '@/utils/util'
import { getUserTenants, switchTenant } from '@/api/app/Tenant'
import { useTenant } from '@/hook/useTenant'

export default {
  name: 'GlobalHeader',
  components: {
    UserMenu,
    SMenu,
    Logo
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      // sidemenu, topmenu
      default: 'sidemenu'
    },
    menus: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    device: {
      type: String,
      required: false,
      default: 'desktop'
    },
    selectedMenuHeader: {
      type: Array,
      required: true,
      default: undefined
    },
    currentMenuHeader: {
      type: Array,
      required: true,
      default: undefined
    }
  },
  data () {
    return {
      logoUrl: null,
      headerBarFixed: false,
      className: 'blue',
      tenants: {},
      currentTenantName: '',
      selectedMenuHeader2: this.selectedMenuHeader
    }
  },
  watch: {
    selectedMenuHeader (val) {
      this.selectedMenuHeader2 = val
    }
  },
  mounted () {
    const _this = this
    handleScrollHeader(direction => {
      _this.handleScroll(direction)
    })
  },
  created () {
    this.className = window.localStorage.getItem('className')

    // 多租户
    const { tenants } = useTenant()
    this.tenants = tenants
    this.currentTenantName = tenants.find(item => item.current)?.name || ''

    const tenant = this.$ls.get('tenant')
    if (tenant) {
      this.logoUrl = '/api/1/tenant/management/logo?id=' + tenant.id
      document.title = tenant.name
    }
  },
  methods: {
    handleScroll (direction) {
      if (this.autoHideHeader) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (direction === 'up') {
          this.headerBarFixed = false
        } else {
          scrollTop > 100 && (this.headerBarFixed = true)
        }
      } else {
        this.headerBarFixed = false
      }
    },
    toggle () {
      this.$emit('toggle')
    },
    onClickHMenu (target) {
      if (target.key === 'dashboard') {
        this.$router.push({ path: '/dashboard/workplace' })
      } else if (target.key === 'task') {
        this.$router.push({ path: '/workflow/task' })
      } else if (target.key === 'passTask') {
        this.$router.push({ path: '/workflow/pass' })
      }
    },
    routePushOrRefresh (path) {
      if (this.$route.fullPath === path) {
        this.$router.go(0)
      } else {
        this.$router.push({ path: path })
      }
    },
    save (className) {
      this.className = className
      window.localStorage.setItem('className', className)
    },
    onSwitchTenant (tenantId) {
      switchTenant(tenantId).then(resp => {
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-menu-horizontal {
  border-bottom: none;
}

.header {
  height: 50px;
  padding: 0 12px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 21, 41, 0.08);
  display: flex;
  color: #000;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  transition: all .3s;

  .logo {
    flex: 0 0 auto;
    margin-right: 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    padding-right: 12px;

    a {
      display: flex;
      align-items: center;

      img {
        height: 24px;
      }

      h1 {
        line-height: 1;
        margin: 0 0 0 8px;
        color: rgb(255, 255, 255);
      }
    }
  }

  .header_con {
    width: 100%;
    display: flex;

    .enterprise {
      display: flex;
      align-items: center;
      width: 199px;
      flex: 0 0 auto;

      > .text {
        width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .item {
    flex: 0 0 auto;
    padding: 0 12px;
    &:hover{
      background: rgba(0,0,0,0.2);
      cursor: pointer;
    }
  }
  .header_menu {
    width: 100%;
    overflow: hidden;
    .ant-menu-item{
      padding: 0 12px;
      line-height: 50px;
      top: 0;
    }
  }
  .search {
    position: relative;
    height: 32px;
    margin: auto 12px;

    .anticon {
      position: absolute;
      font-size: 16px;
      top: 0;
      left: 8px;
      bottom: 0;
      margin: auto;
      line-height: 24px;
      height: 20px;
    }

    input {
      height: 32px;
      display: block;
      background: #f1f1f1;
      outline: none;
      color: #000;
      border-radius: 4px;
      width: 300px;
      font-size: 12px;
      padding: 0 12px 0 28px;
      border: 1px solid #f1f1f1;
      transition: all .3s;

      &::-webkit-input-placeholder {
        color: #8c8c8c;
      }

      &:-moz-placeholder {
        color: #8c8c8c;
      }

      &::-moz-placeholder {
        color: #8c8c8c;
      }

      &:-ms-input-placeholder {
        color: #8c8c8c;
      }

      &:focus {
        border: 1px solid #1890ff;
      }
    }
  }

  &.blue,&.black {
    background: linear-gradient(197deg, #1C42A3 0%, #568FEB 100%);
    color: #fff;

    .header_menu {
      background: none;
      .ant-menu-item{
        color: #fff;
        border-bottom: none;
        &.ant-menu-item-selected{
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .search {
      input {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: #fff;

        &::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.55);
        }

        &:-moz-placeholder {
          color: rgba(255, 255, 255, 0.55);
        }

        &::-moz-placeholder {
          color: rgba(255, 255, 255, 0.55);
        }

        &:-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.55);
        }
      }
    }
  }
  &.black{
    background: linear-gradient(197deg, #313b4f 0%, #02152f 100%);

    .header_menu {
      .ant-menu-item{
        &.ant-menu-item-selected{
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

</style>
