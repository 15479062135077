import Vue, { Ref } from 'vue'
import dayjs from 'dayjs'
import _ from 'lodash'
import { QueryPage } from '@/types/basic'

export const getTableIdx = (tableIdx: number, pagination: Ref<QueryPage>) => {
  const { page = 1, pageSize = DEFAULT_PAGE_SIZE } = pagination.value
  return 1 + tableIdx + (page - 1) * pageSize
}

export const DEFAULT_PAGE_SIZE = 20

/**
 * 保存分页数据至 localStorage 中
 * 数据形如
 * { 74fcb4bb-0123-1000-e000-0003c0a864cb:
 * { "310d427b-d451-4732-adf5-3ff685873582":
 * { pagination: { "pageSize": 10 },expires:1680601175270 } }
 * }
 * @param key 表格唯一标识
 */
export const useTablePaginationHistory = (key: string) => {
  const name = 'useTablePaginationHistory'

  const getExpires = () => dayjs().add(7, 'day').valueOf()

  const save = (pagination: QueryPage) => {
    const lsStore = Vue.ls.get(name, {})
    _.set(lsStore, key, { ...pagination, expires: getExpires() })

    // 删除过期数据
    Object.keys(lsStore).forEach((key: string) => {
      if (lsStore[key].expires < dayjs().valueOf()) {
        delete lsStore[key]
      }
    })

    // 删除默认的节省空间
    if (pagination.pageSize === DEFAULT_PAGE_SIZE) {
      delete lsStore[key]
    }
    Vue.ls.set(name, lsStore)
  }

  const get = (): QueryPage => {
    const lsStore = Vue.ls.get(name, {})
    return _.get(lsStore, key, { pageSize: DEFAULT_PAGE_SIZE })
  }

  return {
    save,
    get
  }
}
