export default [
  {
    path: '/claim/view',
    name: 'ClaimView',
    hidden: true,
    component: () => import('@epp/claim/ClaimView'),
    meta: { title: '报销详情' }
  },
  {
    path: '/claim/edit',
    name: 'ClaimEdit',
    hidden: true,
    component: () => import('@epp/claim/ClaimEdit'),
    meta: { title: '修改报销申请' }
  }
]
