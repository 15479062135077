<template>
  <div>
    <!-- pageHeader , route meta :true on hide -->
    <page-header v-if="!$route.meta.hiddenHeaderContent" :title="pageTitle" :logo="logo" :avatar="avatar">
      <slot slot="xwfMenu" name="xwfMenu"></slot>
      <slot slot="action" name="action"></slot>
      <slot slot="content" name="headerContent"></slot>
      <div slot="content" v-if="!this.$slots.headerContent && description">
        <p style="font-size: 14px;color: rgba(0,0,0,.65)">{{ description }}</p>
        <div class="link">
          <template v-for="(link, index) in linkList">
            <a :key="index" :href="link.href">
              <a-icon :type="link.icon"/>
              <span>{{ link.title }}</span>
            </a>
          </template>
        </div>
      </div>
      <slot slot="extra" name="extra">
        <div class="extra-img">
          <img v-if="extraImage" :src="extraImage"/>
        </div>
      </slot>
      <div slot="pageMenu">
        <div class="page-menu-search" v-if="search">
          <a-input-search
              style="width: 80%; max-width: 522px;"
              placeholder="请输入..."
              size="large"
              enterButton="搜索"
          />
        </div>
        <div class="page-menu-tabs" v-if="tabs && tabs.items">
          <!-- @change="callback" :activeKey="activeKey" -->
          <a-tabs :tabBarStyle="{margin: 0}" :activeKey="tabs.active()" @change="tabs.callback">
            <a-tab-pane v-for="item in tabs.items" :tab="item.title" :key="item.key"></a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </page-header>

    <div class="content" :style="flexFull?'display:flex;':''">
      <div class="page-header-index-wide" :style="flexFull?'min-height: 100%;flex:1':''">
        <slot>
          <!-- keep-alive  -->
          <router-view ref="content"></router-view>
        </slot>
      </div>
    </div>

    <ABackTop :target="findContent" style="right: 80px"/>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import { useDocTitle } from '@/hook/useDocTitle'

// const { set, reset } = useDocTitle()

export default {
  name: 'PageView',
  components: {
    PageHeader
  },
  props: {
    avatar: {
      type: String,
      default: null
    },
    title: {
      type: [String, Boolean],
      default: true
    },
    logo: {
      type: String,
      default: null
    },
    /**
     * PageView 全页面
     */
    flexFull: {
      type: Boolean,
      default: false
    },
    docTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      pageTitle: null,
      description: null,
      linkList: [],
      extraImage: '',
      search: false,
      tabs: {},
      docTitleInst: null
    }
  },
  computed: {
    rootStyle () {
      if (this.$route.meta.hiddenHeaderContent) {
        return ''
      }

      let baseMargin = 'margin: -24px -24px 0px;'

      if (this.flexFull) {
        return `${baseMargin}height: 100%;display: flex;flex-direction: column;`
      }

      return baseMargin
    }
  },
  watch: {
    docTitle (val) {
      if (val) {
        this.docTitleInst.set(this.docTitle)
      }
    }
  },
  created () {
   this.docTitleInst = useDocTitle()
  },
  mounted () {
    this.getPageMeta()
  },
  updated () {
    this.getPageMeta()
  },
  beforeDestroy () {
    this.docTitleInst.reset()
  },
  methods: {
    findContent () {
      return document.querySelector('.content')
    },
    getPageMeta () {
      // eslint-disable-next-line
      this.pageTitle = (typeof (this.title) === 'string' || !this.title) ? this.title : this.$route.meta.title

      const content = this.$refs.content
      if (content) {
        if (content.pageMeta) {
          Object.assign(this, content.pageMeta)
        } else {
          this.description = content.description
          this.linkList = content.linkList
          this.extraImage = content.extraImage
          this.search = content.search === true
          this.tabs = content.tabs
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 0 20px 12px 20px;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 100px);
  box-sizing: border-box;
  margin-top: 0 !important;

  &::-webkit-scrollbar {
    width: 12px;
    height: 200px;
  }

  &::-webkit-scrollbar-thumb {
    background-repeat: no-repeat;
    background-position: center;
    background-color: #bbbbbb;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #868686;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .link {
    margin-top: 16px;

    &:not(:empty) {
      margin-bottom: 16px;
    }

    a {
      margin-right: 32px;
      height: 24px;
      line-height: 24px;
      display: inline-block;

      i {
        font-size: 24px;
        margin-right: 8px;
        vertical-align: middle;
      }

      span {
        height: 24px;
        line-height: 24px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.page-menu-search {
  text-align: center;
  margin-bottom: 16px;
}

.page-menu-tabs {
  margin-top: 48px;
}

.extra-img {
  text-align: center;
  width: 195px;

  img {
    width: 100%;
  }
}

.mobile {
  .extra-img {
    margin-top: 0;
    text-align: center;
    width: 96px;

    img {
      width: 100%;
    }
  }
}
</style>
