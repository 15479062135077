import introJs from 'intro.js'
import 'intro.js/introjs.css'
import { axios } from '@/utils/request'
import qs from 'qs'
import configGuides from '@/utils/guideSteps'
import config from '@/config/defaultSettings'

/**
 * 此方法会过滤已看过的指引 且过滤页面不存在的元素
 * @param pageKey
 * @param steps
 */
function showWantedIntro (pageKey, steps) {
  axios.get('/api/1/intro/get_page_read_intro.json?pageKey=' + pageKey).then((resp) => {
    // 读取用户看过的指引
    let viewedList = resp.result.map(item => item.introKey)
    // 过滤出未看的指引
    let wantedList = steps.filter(item => {
      return !viewedList.includes(item.introKey)
    })
    // 过滤掉页面不存在的元素
    wantedList = wantedList.filter(item => {
      const dom = document.querySelector(item.element)
      return dom != null
    })
    // 显示指引
    showAllIntro(pageKey, wantedList)
  })
}

function showWantedAgainIntro (pageKey, steps) {
  // 过滤掉页面不存在的元素
  let wantedList = steps.filter(item => {
    const dom = document.querySelector(item.element)
    return dom != null
  })
  // 显示指引
  showAllIntro(pageKey, wantedList)
}

function showAllIntro (pageKey, steps) {
  introJs().setOptions({
    steps: steps,
    prevLabel: '上一条',
    nextLabel: '下一条',
    skipLabel: '结束指引',
    doneLabel: '结束指引',
    exitOnEsc: false, /* 是否使用键盘Esc退出 */
    exitOnOverlayClick: false/* 是否允许点击空白处退出 */
  }).oncomplete(function () {
    // 点击结束按钮后执行的事件
  }).onchange(function (targetElement) {
    const introKey = targetElement.getAttribute('introKey')
    // goStepIntro(pageKey, introKey)
  }).onexit(function () {
    // 点击跳过按钮后执行的事件
    // goExitIntro(pageKey, steps)
    document.getElementById('app').classList.remove('guides-setting-class')
  }).start()

  if (steps.length > 0) {
    document.getElementById('app').classList.add('guides-setting-class')
  }
}

function goExitIntro ($pageKey, $steps) {
  let stepArr = []
  $steps.forEach(item => {
    stepArr.push(item.introKey)
  })
  let data = {
    pageKey: $pageKey,
    introKeys: stepArr.join(',')
  }
  axios.post('/api/1/intro/step_skip.json', qs.stringify(data)).then(resp => console.log(resp))
}

function goStepIntro ($pageKey, $introKey) {
  let data = {
    pageKey: $pageKey,
    introKey: $introKey
  }
  axios.post('/api/1/intro/step.json', qs.stringify(data)).then(resp => console.log(resp))
}

/**
 * 加载指引
 * @param $type
 */
function loadGuideIntro ($type) {
  if (!config.guideActive) {
    return
  }
  const pageKey = 'NormalPageKey'
  let steps = [
    {
      introKey: '在需要指引的标签上参数introKey的参数值',
      element: '在需要指引的标签上参数class的参数值',
      title: '指引的标题（非必要）',
      intro: '指引内容（必要，可使用标签）'
    }
  ]

  steps = steps.concat(configGuides)

  if ($type === 'again') {
    showWantedAgainIntro(pageKey, steps)
  } else {
    showWantedIntro(pageKey, steps)
  }
}

/**
 * 模态框 内的指引
 * @constructor
 */
function modalLoadIntro ($type) {
  const pageKey = 'NormalPageKey'
  let steps = [
    {
      introKey: 'Workflow-History-XianShiZaiBan',
      element: '.intro-Workflow-History-XianShiZaiBan',
      title: '',
      intro: '点击“显示在办”，列出办件在谁手中。'
    },
    {
      introKey: 'Workflow-History-ZaiBanSearch',
      element: '.intro-Workflow-History-ZaiBanSearch',
      title: '',
      intro: '此处可搜索某人的相关记录、某环节的相关记录。'
    }
  ]
  if ($type === 'again') {
    showWantedAgainIntro(pageKey, steps)
  } else {
    showWantedIntro(pageKey, steps)
  }
}

export {
  showWantedIntro,
  showWantedAgainIntro,
  showAllIntro,
  loadGuideIntro,
  modalLoadIntro,
  goStepIntro,
  goExitIntro
}
