import FxWSClient from '@/utils/wsc-ts'
import { Message, Subscription } from 'stompjs'
import { EventBus } from '@/event-bus'
import Cookie from 'js-cookie'

export interface SubscribeParams<T> {
  url: string
  onReceive: (result?: T, mate?: Message) => void
}

const useStomp = () => {
  const debug = Cookie.get('ws-debug')
  const ws = FxWSClient.getInstance({ debug: debug || false })

  const _subscribe = <T>({ url, onReceive }: SubscribeParams<T>) => {
    if (!ws.stompClient) {
      throw new Error('ws 尚未连接')
    }
    const res = ws.stompClient.subscribe(url, (msg) => {
      const result = JSON.parse(msg.body) as T
      onReceive(result, msg)
    })
    console.debug('stomp subscribe success', res.id, url)
    return res
  }

  const subscribe = <T>(params: SubscribeParams<T>) => {
    return new Promise<Subscription>((resolve) => {
      // 成功（包括断联）都会触发此方法
      EventBus.$on('WebSocketConnectSuccess', () => {
        resolve(_subscribe(params))
      })

      // 避免首次进入页面时，ws 还未连接，导致无法订阅
      if (ws.isConnected) {
        return resolve(_subscribe(params))
      }
    })
  }

  const unsubscribe = (id: string) => {
    ws.stompClient?.unsubscribe(id)
  }

  return { subscribe, unsubscribe }
}

export default useStomp
