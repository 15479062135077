/**
 * 基础路由
 * @type { *[] }
 */
import sensorRouters from '@/config/sensorRouters/index'
export const constantRouterMap = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login')
  },
  {
    path: '/404',
    component: () => import('../views/exception/404')
  },
  {
    path: '/',
    redirect: '/login',
    name: '首页',
    hidden: true
  },
  {
    path: '/workflow/design/add',
    name: 'WorkflowDesignAdd',
    component: () => import('../views/workflow/design/editor/WFAddFull')
  },
  {
    name: 'redirect',
    hidden: true,
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/RedirectIndex')
  },
  /**
   * 设备传感数据h5页面
   */
  ...sensorRouters
]
