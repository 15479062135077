/* eslint-disable no-mixed-operators */
import Vue from 'vue'
import router from './router'
import store from './store'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// 传感器h5页面白名单
const sensorWhiteList = ['sensor', 'sensor_error_page', 'sensor_mine', 'wx_scan_success']
const whiteList = ['login', ...sensorWhiteList] // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    NProgress.start() // start progress bar
  }

  // 记录上一步路径，目前流程走完下一步时，要根据来源跳转
  Vue.ls.set('_PREVIOUS_PATH', from.path)

  // 可能是其它地方开新窗口出来的，比如IM聊天工具，在IM里已经登录，开新窗口打开页面却是没有登录
  prepareAccessToken(to, from)

  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token */
    if (to.path === '/login') {
      next({ path: '/dashboard/workplace' })
      NProgress.done()
    } else {
      if (!store.getters.userInit) {
        store.dispatch('GetInfo').then(res => {
          let roles = {}
          if (res.code === 200) {
            roles = res.result.user.permissions
          }
          if (roles['tenant.system.manage']) {
            throw new Error('exit...')
          }
          const modules = res.result.module
          store.dispatch('GenerateRoutes', { roles, modules }).then(() => {
            // 根据roles权限生成可访问的路由表
            // 动态添加可访问路由表
            for (let x of store.getters.addRouters) {
              router.addRoute(x)
            }
            // router.addRoutes(store.getters.addRouters)
            const redirect = decodeURIComponent(from.query.redirect || to.path)
            if (to.path === redirect) {
              // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
              next({ ...to, replace: true })
            } else {
              // 跳转到目的路由
              next({ path: redirect })
            }
          })
        }).catch(() => {
          store.dispatch('Logout').then(() => {
            next({ path: '/login', query: { redirect: to.fullPath } })
          })
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

/**
 * Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action:[method] , 如下：
 *    <i-button v-action:add >添加用户</a-button>
 *    <a-button v-action:delete>删除用户</a-button>
 *    <a v-action:edit @click="edit(record)">修改</a>
 *
 *  - 当前用户没有权限时，组件上使用了该指令则会被隐藏
 *  - 当后台权限跟 pro 提供的模式不同时，只需要针对这里的权限过滤进行修改即可
 *
 *  @see https://github.com/sendya/ant-design-pro-vue/pull/53
 */
const action = Vue.directive('action', {
  bind: function (el, binding, vnode) {
    const actionName = binding.arg
    const roles = store.getters.roles
    const elVal = vnode.context.$route.meta.permission
    const permissionId = elVal instanceof String && [elVal] || elVal
    roles.permissions.forEach(p => {
      if (!permissionId.includes(p.permissionId)) {
        return
      }
      if (p.actionList && !p.actionList.includes(actionName)) {
        el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
      }
    })
  }
})

function prepareAccessToken (to, from) {
  const queryToken = to.query['X-AUTH-TOKEN']
  const localToken = Vue.ls.get(ACCESS_TOKEN)

  if (queryToken) {
    if (queryToken !== localToken) {
      Vue.ls.set(ACCESS_TOKEN, queryToken, 7 * 24 * 60 * 60 * 1000)
      store.commit('SET_TOKEN', queryToken)
      store.commit('SET_USER_INIT', false)
    }
  } else {
    // 没有传递token什么操作都不需要
  }
}

export {
  action
}
