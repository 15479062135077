<template>
    <span>
        <a-tooltip :title="title" placement="bottom">
          <a-icon class="wifi-icon" :style="statusClass" type="wifi"/>
        </a-tooltip>
    </span>
</template>

<script>
import { EventBus } from '../../event-bus'
import useStomp from '@/hook/useStomp'

export default {
  name: 'HeaderWebSocketStatus',
  components: {},
  computed: {
    statusClass: function () {
      let color = 'red'
      if (this.status === 1) {
        color = 'darkorange'
      } else if (this.status === 2) {
        color = 'green'
      }
      return {
        color: color
      }
    }
  },
  data () {
    return {
      status: 0,
      title: 'WebSocket连接失败'
    }
  },
  created () {
    useStomp()
    EventBus.$on('WebSocketStatus', this.onWebSocketStatusChanged)
  },
  beforeDestroy: function () {
    EventBus.$off('WebSocketStatus', this.onWebSocketStatusChanged)
  },
  methods: {
    onWebSocketStatusChanged (status) {
      this.status = status
      if (status === 0) {
        this.title = 'WebSocket连接失败'
      } else if (status === 1) {
        this.title = 'WebSocket连接中...'
      } else if (status === 2) {
        this.title = 'WebSocket已连接'
      } else {
        this.title = 'WebSocket未知状态'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wifi-icon {
  font-size: 16px;
  padding: 4px;
  color: #ccc;
}
</style>
