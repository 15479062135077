<template>
  <a-layout-sider
    :class="['sider', isDesktop() ? null : 'shadow', fixSiderbar ? 'ant-fixed-sidemenu' : null ]"
    class="light"
    width="210px"
    :collapsible="collapsible"
    v-model="collapsed"
    :trigger="null"
    v-if="menus.length > 0 && menus[0].name !== 'Workplace'">
    <s-menu
      :collapsed="collapsed"
      :menu="menus"
      :theme="null"
      :mode="mode"
      @select="onSelect"></s-menu>
    <div class="f_trigger">
      <a-icon
          v-if="device==='mobile'"
          :type="collapsed ? 'caret-left' : 'caret-right'"
          @click="toggle"></a-icon>
      <a-icon
          v-else
          :type="collapsed ? 'caret-right' : 'caret-left'"
          @click="toggle"/>
      <img :src="$_c + 'img/icon5.svg'"/>
    </div>
    <div v-if="!collapsed" class="version-box">
      <ATooltip>
        <template #title>
          后端版本: {{serverVersion}}
        </template>
        <a href="https://manylink.feishu.cn/wiki/XyVUwHQRBiqGBqk6dSCczhN1nq5" target="_blank" class="version-text">v1.5.0</a>
      </ATooltip>
    </div>
    <div class="IOT_sidebar">
      <a-popover placement="topLeft">
        <template slot="content">
          <div style="width: 240px;height: 333px">
            <img style="width: 240px;height: 333px" :src="$_c + 'img/iot/wxkf.jpg'"/>
          </div>
        </template>
        <div class="item">
          <img :src="$_c + 'img/iot/kf.svg'"/>
          <div>客服</div>
        </div>
      </a-popover>
    </div>
  </a-layout-sider>
</template>

<script>
import Logo from '@/components/tools/Logo'
import SMenu from './index'
import { mixin, mixinDevice } from '@/utils/mixin'
import { getVersion } from '@/api/epp/iot/open'

export default {
  name: 'SideMenu',
  components: { Logo, SMenu },
  mixins: [mixin, mixinDevice],
  props: {
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    // device: {
    //   type: String,
    //   required: false,
    //   default: 'desktop'
    // },
    menus: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      serverVersion: ''
    }
  },
  created () {
    this.getServerVersion()
  },
  methods: {
    async getServerVersion () {
      const res = await getVersion()
      if (res.result) {
        this.serverVersion = res.result.server
      }
    },
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    toggle () {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="less" scoped>
.f_trigger{
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
  height: 80px;
  margin: auto;
  img{
    height: 100%;
    z-index: 1;
    display: block;
  }
  i{
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 6px;
    &:hover{
      color: #0765ff;
    }
  }
}

.version-box{
  position: absolute;
  bottom: 0
}
.version-text{
  color: #b0b0b0;
  display: block;
  font-size: 16px;
  position: relative;
  width: 210px;
  margin-bottom: 8px;
  text-align:center;
}
.version-text:hover{
  color: #0765ff;
}
</style>
