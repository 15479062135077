import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=68a1b0e8&scoped=true&"
import script from "./Breadcrumb.vue?vue&type=script&lang=js&"
export * from "./Breadcrumb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a1b0e8",
  null
  
)

export default component.exports