import Vue from 'vue'
import VueStorage from 'vue-ls'
import config from '@/config/defaultSettings'

// base library
import Antd from 'ant-design-vue'
import Viser from 'viser-vue'
import VueCropper from 'vue-cropper'
import 'ant-design-vue/dist/antd.less'

// import Hello from 'fxhello'
// import 'fxhello/lib/fxhello.css'

// ext library
import VueClipboard from 'vue-clipboard2'
import PermissionHelper from '@/utils/helper/permission'
// import '@/components/use'

import helper from '../utils/helper.js'
import * as guide from '../utils/guide.js'

VueClipboard.config.autoSetContainer = true

// Vue.use(Hello)

Vue.use(Antd)
Vue.use(Viser)

Vue.use(VueStorage, config.storageOptions)
Vue.use(VueClipboard)
Vue.use(PermissionHelper)
Vue.use(VueCropper)

Vue.prototype.$helper = helper
Vue.prototype.$guide = guide
