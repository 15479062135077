import request from '@/utils/requestWrapper'
import { Result } from '@/types/basic'

const api = {
  getVersion: '/api/1/open/version'
}

export const getVersion = () =>
  request.get<
    Result<{
      server: string
    }>
  >(api.getVersion)
