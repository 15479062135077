import Vue from 'vue'

const defaultAction = (el: HTMLImageElement, binding) => {
  if (!el) {
    return
  }
  el.onerror = function () {
    // 一次性函数，避免无限循环
    el.onerror = null
    el.src = binding.value || '/img/cp_img.png'
  }
}

Vue.directive('default-img-src', {
  inserted: defaultAction,
  update: defaultAction
})
