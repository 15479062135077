import { StoreUser } from '@/store/modules/user'

const getters = {
  device: state => state.app.device as string,
  theme: state => state.app.theme as string,
  color: state => state.app.color as string,
  user: state => state.user as StoreUser,
  token: state => state.user.token as string,
  avatar: state => state.user.avatar as string,
  nickname: state => state.user.name as string,
  userInit: state => state.user.userInit as boolean,
  addRouters: state => state.permission.addRouters,
  multiTab: state => state.app.multiTab
}

export default getters
