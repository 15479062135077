export default {
  nl2br (str) {
    if (!str) {
      return ''
    }
    return str.replace(/(?:\r\n|\r|\n)/g, '<br/>')
  },

  getSortSql (parameter, sortMap) {
    const field = parameter.sortField
    const order = parameter.sortOrder

    if (field) {
      let target = sortMap.find(item => item.sortField === field)
      if (target && order && order !== '') {
        if (order === 'descend') {
          target.sortOrder = 'desc'
        } else if (order === 'ascend') {
          target.sortOrder = 'asc'
        }

        return target.sortFieldOrig + '@' + target.sortOrder
      }
    }

    return ''
  },
  copyDeep (templateData) {
    // templateData 是要复制的数组或对象，这样的数组或者对象就是指向新的地址的
    return JSON.parse(JSON.stringify(templateData))
  },
  showOpen (url, tag, $access_token, relate) {
    if (relate !== null && relate !== undefined) {
      let ranum = 1
      let reg = /^[0-9]+.?[0-9]*$/
      if (reg.test(relate)) {
        if (relate > 1) {
          ranum = 1
        } else {
          ranum = relate
        }
      }
      let iWidth = (window.screen.availWidth) * ranum
      let iHeight = (window.screen.availHeight - window.screen.availHeight * 0.1) * ranum
      let iTop = (window.screen.availHeight - 30 - iHeight) / 2
      let iLeft = (window.screen.availWidth - 10 - iWidth) / 2
      window.open(url, tag, 'width=' + iWidth + ',height=' + iHeight + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=no')
    } else {
      window.open(url, tag, 'width=' + (window.screen.availWidth) + ',height=' + (window.screen.availHeight - window.screen.availHeight * 0.1) + ',top=0,left=0,toolbar=no,menubar=no,scrollbars=no')
    }
  }
}
