export default [
  {
    path: '/analysis/cost/detail',
    name: 'CostConf',
    hidden: true,
    component: () => import('@/epp/analysis/cost/CostConf.vue'),
    meta: { title: '费用配置详情' }
  },
  {
    path: '/ea/config/formula',
    parentPath: '/ea/config',
    name: 'WmBizFormula',
    hidden: true,
    component: () => import('@/epp/analysis/wmbusiness/config/cdbiz/WmBizFormula.vue'),
    meta: { title: '全局公式' }
  }
]
