import SockJS from 'sockjs-client'
import Stomp, { Client } from 'stompjs'
import { EventBus } from '@/event-bus'

/**
 * Created by Xuz on 2021/04/06.
 *
 * WebSocket client
 */
class FxWSClient {
  stompClient: Client | undefined
  isConnected
  sockJS

  options = {
    debug: false,
    token: '',
    ctx: ''
  }

  connectCallback = () => {
  }
  private static instance: FxWSClient;

  constructor (options) {
    const defaultOptions = {
      ctx: process.env.VUE_APP_CTX_PATH
    }

    if (options) {
      this.options = { ...defaultOptions, ...options }
    }
    this._connect()
  }

  static getInstance (options) {
    if (!FxWSClient.instance) {
      FxWSClient.instance = new FxWSClient(options)
    }
    return FxWSClient.instance
  }

  connect (connectCallback) {
    this.connectCallback = connectCallback
    this._connect()
  }

  disconnect () {
    if (this.stompClient != null) {
      this.stompClient.disconnect(() => console.log('disconnected...'))
    }
  }

  onConnectSuccess (frame) {
    console.log('Connected...')
    this.emitWebSocketStatus(2)

    this.isConnected = true

    if (this.connectCallback) {
      this.connectCallback()
    }
  }

  reConnectWebSocket () {
    console.log('WebSocket reconnecting in 5 seconds...')
    this.emitWebSocketStatus(0)
    this.isConnected = false
    // 使用 stomp.js 自带重连机制
    setTimeout(() => {
      this._connect()
    }, 8000)
  }

  _connect () {
    this.emitWebSocketStatus(1)
    // 获取STOMP子协议的客户端对象
    const headers = {}
    if (this.options.token) {
      headers['X-Auth-Token'] = this.options.token
    }
    this.sockJS = new SockJS(this.options.ctx + 'ws/stomp')
    this.stompClient = Stomp.over(this.sockJS)
    if (!this.options.debug) {
      this.stompClient.debug = () => {
      }
    }

    this.stompClient.connect(
      headers,
      (frame) => {
        this.onConnectSuccess(frame)
        EventBus.$emit('WebSocketConnectSuccess', frame)
      },
      () => {
        this.reConnectWebSocket()
      }
    )
  }

  /**
   * 0:断线 1:连接中 2:已连接
   * @param status
   */
  emitWebSocketStatus (status) {
    EventBus.$emit('WebSocketStatus', status)
  }
}

export default FxWSClient
