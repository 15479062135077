import { EmitFn } from 'vue/types/v3-setup-context'
import { useAsync } from '@/hook/useAsync'
import { Ref } from 'vue'
import { useFormCheckHasErr } from '@/hook'
import { FormModel } from 'ant-design-vue'

export type modalProp = {
  id?: string
  name: string
  loading: Ref<boolean>
  visible?: boolean
  emit: EmitFn<['update:visible', 'submit']>
  formInst?: Ref<FormModel | null | undefined>
  loadFunc?: () => Promise<any>
  saveFunc: () => Promise<any>
  updateFunc: () => Promise<any>
}

/**
 * 模态框通用 hook
 * @param id 主键
 * @param name 模态框名称
 * @param loading 加载状态
 * @param visible
 * @param emit 模态框事件
 * @param formInst 表单实例
 * @param loadFunc
 * @param saveFunc 添加方法
 * @param updateFunc 更新方法
 */
export const useModal = ({
  id,
  name,
  loading,
  visible,
  emit,
  formInst,
  loadFunc,
  saveFunc,
  updateFunc
}: modalProp) => {
  const title = id ? `编辑${name}` : `添加${name}`

  const onClose = () => {
    emit('update:visible', false)
  }

  const onSubmit = async () => {
    // 只有传了表单实例才检查
    if (formInst && (await useFormCheckHasErr(formInst))) {
      return
    }

    const func = id ? updateFunc : saveFunc

    useAsync(loading, func()).then(() => {
      onClose()
      emit('submit')
    })
  }

  const initData = async () => {
    if (!loadFunc) {
      return
    }
    if (visible) {
      await useAsync(loading, loadFunc())
    }
  }

  if (visible) {
    initData()
  }

  return {
    title,
    onClose,
    onSubmit,
    initData
  }
}
