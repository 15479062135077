import { useStore } from '@/store'
import { getUserTenants, TenantsResult } from '@/api/app/Tenant'

export const useTenant = () => {
  const tenants = useStore().getters.user.tenants as TenantsResult[]
  const currentTenant = () => tenants.find(t => t.current)
  return { tenants, currentTenant }
}

export const useTenantNow = async () => {
  const tenants = await getUserTenants().then(resp => {
    return resp.result as TenantsResult[]
  })
  const currentTenant = () => tenants.find(t => t.current)
  return { tenants, currentTenant }
}
