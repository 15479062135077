export default [
  {
    introKey: 'Check-Form-Add-Btn',
    element: '.intro-Check-Form-Add-Btn',
    title: '新增计划按钮在这里',
    intro: '点“添加”即可添加计划信息。'
  },
  {
    introKey: 'Check-Form-Status-Btn',
    element: '.intro-Check-Form-Status-Btn',
    title: '',
    intro: '点击不同“状态”按钮，可快速查询相关状态的信息内容。'
  },
  {
    introKey: 'intro-xjsb-t1',
    element: '.intro-xjsb-t1',
    title: '物模型/产品目录',
    intro: '可根据自己设备场景创建'
  },
  {
    introKey: 'intro-xjsb-t2',
    element: '.intro-xjsb-t2',
    title: '更多操作',
    intro: '新增、编辑、删除都在此处'
  }
]
