import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap } from '@/config/const.config'
import { EventBus } from '@/event-bus'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

// 关于当前页面
Router.prototype.closeCurrentPage = function () {
  let current = this.history.current
  console.log(current)
}

// 关闭当前页面然后跳转到指定页面
Router.prototype.closeCurrentPageAndPush = function (pushParam) {
  let current = this.history.current
  EventBus.$emit('FxMultiTabClose', current.fullPath)
  this.push(pushParam)
}

export default router
