import request from '@/utils/requestWrapper'
import { Result } from "@/types/basic";
//
// 登录模块重构
// Xuz on 2021/12/31.
// xuz@live.cn
//
/**
 * 登录系统
 * @param params - {account:账号, password:密码, captchaId:验证码ID, captcha:验证码, deviceId:设备ID, deviceName:设备名称, os:操作系统}
 */
export function login (params:object) {
  return request.post<Result>('/api/5/device/secure_login', params)
}

/**
 * 读取当前用户信息
 */
export function getCaptcha () {
  return request.get('/api/2/captcha/get_captcha')
}

/**
 * 读取当前用户信息
 */
export function getInfo () {
  return request.get<Result>('/api/1/user/info')
}

/**
 * 退出登录
 */
export function logout () {
  return request.post<Result>('/api/1/device/logout')
}
