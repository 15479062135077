import { FormModel, notification } from 'ant-design-vue'
import { Ref, unref } from 'vue'

/**
 * 模态框错误提示
 * @param desc
 * @param params 可选参数
 */
export const useWaring = (desc: string[], params?: { msg?: string }) => {
  const defaultMsg = '提交失败'
  const msg = params ? params.msg || defaultMsg : defaultMsg
  notification.warning({
    message: msg,
    description: desc.join('\n'),
    style: {
      whiteSpace: 'pre-wrap'
    }
  })
}

/**
 * 通知错误提示
 * @param e Antdv 表单的错误对象
 * @param params 可选参数
 */
export const useValidateWaring = (e: Record<string, any>, params?: { msg?: string }) => {
  const defaultMsg = '提交失败'
  const msg = params ? params.msg || defaultMsg : defaultMsg
  notification.warning({
    message: msg,
    description: Object.keys(e)
      .map((key) => e[key][0].message)
      .join('\n'),
    style: {
      whiteSpace: 'pre-wrap'
    }
  })
}

/**
 * 封装表单异步校验，返回 object 时代表有错误
 * @param {FormModel | Ref<FormModel | null | undefined>} formObj 表单对象
 * @return {Promise<false | object>} 返回值 如果是 false 说明没有错误，否则返回错误对象
 */
export const useFormCheckHasErr = async (
  formObj?: FormModel | Ref<FormModel | null | undefined>
): Promise<false | object> => {
  const form = unref(formObj)
  return new Promise((resolve, reject) => {
    if (!form) {
      reject(Error('巡检计划实体不存在！'))
      return
    }

    form.validate((valid, err) => {
      if (!valid) {
        useValidateWaring(err)
        resolve(err)
        return
      }
      resolve(false)
    })
  })
}

type ruleItem = {
  key: string
  name: string
  type: 'input' | 'select'
}

/**
 * 生成表单校验规则
 * @param keyList
 */
export const generalRules = (keyList: ruleItem[]) => {
  const result = {}
  keyList.forEach((item) => {
    const { key, name, type } = item
    const action = type === 'input' ? '输入' : '选择'
    result[key] = [{ required: true, message: `请${action}${name}`, trigger: ['change', 'blur'] }]
  })
  return result
}
